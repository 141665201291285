import { Order } from './../interfaces/order';
import { MatSnackBar } from '@angular/material';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URL_TO_CALL } from '../app.module';
import { Observable } from 'rxjs';

@Injectable()
export class OrdersService {

  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar
  ) { }

  getAllOrders(): Observable<Order[]> {
      return this.http.get<Order[]>(`${URL_TO_CALL}/api/Ordini`);
  }
}

