import { ProductsService } from './../../services/products.service';
import { OrdersService } from './../../services/orders.service';
import { Component, OnInit } from '@angular/core';
import { Order } from '../../interfaces/order';
import { User } from '../../interfaces/user';
import { UsersService } from '../../services/users.service';
import { Product } from '../../interfaces/product';

@Component({
    templateUrl: './orders.component.html',
    styleUrls: ['./orders.component.scss']
})
// tslint:disable-next-line:component-class-suffix
export class OrdersComponent implements OnInit {
  orders: Order[];
  clientsId: string[] = [];
  itemsId: string[] = [];
  clients: {[clientId: string]: User} = {};
  items: {[itemId: string]: Product} = {};

  constructor(
    private orderService: OrdersService,
    private usersService: UsersService,
    private productsService: ProductsService
  ) {

  }

  ngOnInit(): void {
    this.orderService.getAllOrders()
    .subscribe((orders: Order[]) => {
      this.orders = orders;
      this.orders.map((o: Order) => {
        if (
          (!this.clientsId.includes(o.clienteId)) &&
          (o.clienteId) &&
          (o.clienteId.length === 24)
        ) {
          this.clientsId.push(o.clienteId);
        }

        if (
          (!this.itemsId.includes(o.carrello.item)) &&
          (o.carrello.item) &&
          (o.carrello.item.length === 24)
        ) {
          this.itemsId.push(o.carrello.item);
        }
      });

      this.clientsId.map((clientId: string) => {
        this.usersService.getUser(clientId)
        .subscribe((user: User) => {
          this.clients[user.id] = user;
        });
      });

      this.itemsId.map((itemId: string) => {
        this.productsService.getItem(itemId)
        .subscribe((product: Product) => {
          this.items[product.id] = product;
        });
      });
    });
  }
}
