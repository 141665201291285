import { HttpClient } from '@angular/common/http';
import { FormControl } from '@angular/forms';
import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { Field } from '../../interfaces/field';

@Component({
    selector: 'app-select-field',
    templateUrl: './select-field.component.html',
    styleUrls: ['../../shared/shared.scss']
})
export class SelectFieldComponent implements OnInit {
    @Input() config: any;
    @Input() key: string;
    @Input() value: string;
    @Input() selected: any;
    @Output() valueUpdated = new EventEmitter<any>();

    values: Field[];
    objects: any;

    constructor(private http: HttpClient) {
    }

    valueChanged() {
        this.valueUpdated.emit({
            value: this.selected,
            key: this.key
        });
    }

    ngOnInit() {
      this.values = this.config.defaultValues;
    }
}
