import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URL_TO_CALL } from '../../app.module';
import {MatTableDataSource, MatSort} from '@angular/material';

@Component({
    templateUrl: './products.page.html',
    styleUrls: ['./products.page.scss']
})
export class ProductsPage implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns = ['name', 'actions'];

    dataSource: any;
    response: any;
    constructor(
        private http: HttpClient,
        private router: Router,
        private route: ActivatedRoute
    ) {
    }

    loadItems() {

        this.http.get(URL_TO_CALL + '/api/Prodotti')
        .subscribe(response => {

            console.log(response);
            this.response = response;
            this.dataSource = new MatTableDataSource(this.response);
            this.dataSource.sort = this.sort;
        });
    }
    ngOnInit() {
        this.loadItems();
    }

    create() {
        this.router.navigate([`/dashboard/products/new`]);
    }

    edit(itemId: number) {
        console.log('editing');
        this.router.navigate([`/dashboard/products/edit/${itemId}`]);
    }

    erase(itemId: number) {
        const array = this.dataSource.filteredData;
        const item = array.filter((obj) => {
            return obj.id === itemId;
        })[0];

        const confirmation = confirm(`Are you sure you want to delete ${item['name']}?`);
        if (confirmation === true) {
            console.log('deleting');
            this.http.delete(`${URL_TO_CALL}/api/Prodotti/${itemId}`)
            .subscribe(response => {
                alert('deleted, please reload!');
                this.dataSource.filteredData = array.filter((obj) => {
                    return obj.id !== itemId;
                })[0];
            });
        }
    }


    /**
     * Set the sort after the view init since this component will
     * be able to query its view for the initialized sort.
     */
    ngAfterViewInit() {
    }
  }
