import { FormBuilder, FormControl, FormGroup , Validators, ValidatorFn} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {Product, Prodotto} from '../../interfaces/product';
import { URL_TO_CALL } from '../../app.module';

import { ProductsService } from '../../services/products.service';
import { MatSnackBar } from '@angular/material';
import { Field } from '../../interfaces/field';
@Component({
  selector: 'app-new-product',
  templateUrl: './new-product.component.html',
  styleUrls: ['./new-product.component.scss']
})
export class NewProductComponent implements OnInit {

  id: string;
  product: any = {};
  productForm: FormGroup;
  categories: any;
  productModel = Prodotto;
  keys: any;
  fields: Field[];
  errors: any[];

  constructor(
      private http: HttpClient,
      private route: ActivatedRoute,
      private fb: FormBuilder,
      private productService: ProductsService,
      public snackBar: MatSnackBar
  ) {
      this.keys = Object.keys(this.productModel);
      for (const key of this.keys) {
        this.product[key] = '';
      }
      this.buildForm();
  }

  buildForm() {

  }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');


    this.http.get(`${URL_TO_CALL}/model/prodotto`)
      .subscribe((remoteModel: any) => {
        const model = remoteModel.info.properties;
        this.fields = model;
        console.warn(this.fields);
        this.createForm();
    });
  }

  createForm() {

    this.keys = Object.keys(this.fields);
    delete(this.keys.id);
    const formObj = {};
    this.keys.map(key => {
      const formControlArray = this.fields[key].required ? [this.fields[key].default, Validators.required] : [this.fields[key].default];
      formObj[key] = formControlArray;
    });
    this.productForm = this.fb.group(formObj);

  }

  addFascia(event: any) {
    // from output
    console.warn(event);
    if ((!this.productForm.value.gcFascePrezzo) || (!this.productForm.value.gcFascePrezzo.fasce)) {
      this.productForm.patchValue({
        gcFascePrezzo: {
          fasce: [event]
        }
      });
      this.productForm.value.gcFascePrezzo.fasce = [event];
      this.snackBar.open('Fascia creata', '' , {
        duration: 2000
      });
    } else {
        this.product.gcFascePrezzo.fasce.push(event);
        this.snackBar.open('Fascia aggiunta', '' , {
          duration: 2000
        });
    }
    this.recalcErrors();
  }

    removeTableau(f: any) {
        this.product.gcFascePrezzo.fasce = this.product.gcFascePrezzo.fasce.filter(obj => {
            return obj !== f;
        });
    }

    valueUpdated(event: any) {
        this.productForm.patchValue({[event.key]: event.value});
        this.productForm.value[event.key] = event.value;
        this.recalcErrors();
    }

    recalcErrors() {
      this.errors = [];
      Object.keys(this.productForm.controls).map((key: string) => {
        const ctrl = <FormControl> this.productForm.controls[key];
        if (ctrl.errors) {
          this.errors.push({key: key, error: ctrl.errors});
        }
      });
    }

    save() {
      this.productService.saveProduct(this.productForm.value);
    }

}
