import { FormControl } from '@angular/forms';
import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
@Component({
    selector: 'app-check-field',
    templateUrl: './check-field.component.html',
    styleUrls: ['../../shared/shared.scss']
})
export class CheckFieldComponent implements OnInit {
    @Input() config: any;
    @Input() key: string;
    @Input() value: string;
    @Input() c: FormControl;
    @Output() valueUpdated = new EventEmitter<any>();

    valueChanged(value: any) {
        this.valueUpdated.emit({
            value: +(value),
            key: this.key
        });
    }

    ngOnInit() {

    }
}
