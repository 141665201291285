import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URL_TO_CALL } from '../app.module';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';

@Injectable()
export class ImagesService {

  constructor(
    private http: HttpClient
  ) { }

  loadAllImages(): Observable<any[]> {
    return this.http.get<any[]>(`${URL_TO_CALL}/api/attachments/images/files`);
  }

  filterByWord(word: string, images: any[]) {
    images.map(img => {
      const match = img.name.match(new RegExp(word, 'i'));
      if (match) {
        console.log('MATCHING', img.name);
        img.visible = true;
      } else {
        img.visible = false;
      }
    });
    return images;
  }
  // /attachments/{container}/files/{file}

  delete(img: any): Observable<any[]> {
    return this.http.delete<any[]>(`${URL_TO_CALL}/api/attachments/images/files/${img.name}`);
  }

  chunkArray() {

  }

}
