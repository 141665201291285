import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ImagesService } from '../../services/images.service';

@Component({
  selector: 'app-images-dialog-component',
  templateUrl: 'images-dialog.html',
  styleUrls: ['./images-dialog.component.scss']
})
export class ImagesDialogComponent {

  imagesContainer = [];

  selected: any;
  constructor(
    public dialogRef: MatDialogRef<ImagesDialogComponent>,
    private imgService: ImagesService,
    @Inject(MAT_DIALOG_DATA) public images: any) {
      console.log(this.images);
      this.images.map(img => {
        img.visible = true;
      });
      this.chunkArray(this.images);
    }

  onNoClick(): void {
    this.dialogRef.close(this.selected);
  }

  chunkArray(array: any[]) {
    const chunk = 4;
    let tempArray = [];
    for (let i = 0, j = array.length; i < j; i += chunk) {
      tempArray = array.slice(i , i + chunk);
      // do whatever
      this.imagesContainer.push(tempArray);
    }

    console.log(this.imagesContainer);
  }

  toggleSelect(img) {
    for (const img of this.images){
      img.selected = false;
    }
    const selected = this.images.filter(obj => {
      return obj['name'] === img['name'];
    })[0];
    selected.selected = true;
    this.selected = selected;
  }

  filterByWord(word: string) {
    this.images = this.imgService.filterByWord(word, this.images);
  }

}
