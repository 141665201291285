import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URL_TO_CALL } from '../../app.module';
import {Category} from '../../interfaces/category';
import { MatSelectChange } from '@angular/material';
@Component({
  selector: 'app-remote-select',
  templateUrl: './remote-select.component.html',
  styleUrls: ['./remote-select.component.scss']
})
export class RemoteSelectComponent implements OnInit {

  @Input() config: any;
  @Input() key: string;
  @Input() value: string;
  @Input() selected: any;
  @Output() valueUpdated = new EventEmitter<any>();

  computedSelected: any;

  categories: Category[];
  constructor(
    private http: HttpClient
  ) { }

  ngOnInit() {
    console.log('RemoteSelectComponent OK');
    this.http.get(`${URL_TO_CALL}/api/Categorie`)
    .subscribe((response: Category[]) => {
      this.categories = response;
      if (this.selected) {
        this.computedSelected = this.categories.filter(category => {
          return category.id === this.selected;
        })[0].id;
      }

    });
  }

  valueChanged(event: MatSelectChange) {
    console.warn('sent', event);
    console.log('TRIGGER!');
    this.valueUpdated.emit({
        value: this.computedSelected,
        key: this.key
    });
  }

}
