import { FormControl } from '@angular/forms';
import { Component, Inject, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ImagesDialogComponent } from './images-dialog.component';
interface Image {
    selected: boolean;
    atime: string;
    container: 'image';
    ctime: string;
    mtime: string;
    name: string;
    size: number;
}

@Component({
    selector: 'app-images-component',
    templateUrl:  './images.component.html',
    styleUrls: ['../../shared/shared.scss']
})
export class ImagesComponent implements OnInit {
    @Input() config: any;
    @Input() key: string;
    @Input() value: string;
    @Output() valueUpdated = new EventEmitter<any>();

    images:  any[];
    selectedImage: Image;

    constructor(
      private http: HttpClient,
      public dialog: MatDialog
    ) {}

    valueChanged(value: any) {
        this.valueUpdated.emit({
            value: +(value),
            key: this.key
        });
    }

    openGallery(): void {
      const dialogRef = this.dialog.open(ImagesDialogComponent, {
        width: '100%',
        height: '50vh',
        data: this.images
      });

      dialogRef.afterClosed().subscribe((result: Image) => {
        console.log('The dialog was closed', result);
        this.selectedImage = result;
        this.valueUpdated.emit({
          value: this.selectedImage,
          key: this.key
      });
      });
    }

    ngOnInit() {
        this.http.get('http://51.255.35.185/api/attachments/images/files')
        .subscribe((response: any) => {
          if (this.value) {
            for (const img of this.value){
                const imgToChange = response.filter((obj) => {
                    return obj.name === img['name'];
                })[0];
                imgToChange.selected = true;
            }
          }
            this.images = response;
        });
    }
}
