import { Component } from '@angular/core';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';

@Component({
    templateUrl:'./login.page.html',
    styleUrls:['./login.page.scss']
})
export class LoginPage {
    loginForm: FormGroup;

    constructor(private fb: FormBuilder){
        this.createForm();
    }

    createForm(){
        this.loginForm = this.fb.group({
            username: ['',Validators.required], // <--- the FormControl called "name"
            password: ['',Validators.required]
          });
    }
}