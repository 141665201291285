import { Component, OnInit } from '@angular/core';
import { ViewChild, AfterViewInit} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { URL_TO_CALL } from '../../app.module';
import { MatTableDataSource, MatSort, MatSnackBar } from '@angular/material';
import { Field } from '../../interfaces/field';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Category } from '../../interfaces/category';
@Component({
  selector: 'app-category-detail',
  templateUrl: './category-detail.component.html',
  styleUrls: ['./category-detail.component.scss']
})
export class CategoryDetailComponent implements OnInit {

  categoryForm: FormGroup;
  fields: Field[];
  keys: string[];
  category: Category;

  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    public snackBar: MatSnackBar
  ) {
  }

  ngOnInit() {

    //  build form
    this.http.get('http://51.255.35.185/model/categoria')
      .subscribe((remoteModel: any) => {
        const model = remoteModel.info.properties;
        this.fields = (model);
        this.buildForm();
    });

  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  buildForm(): void {
    this.keys = Object.keys(this.fields);
    const formObj = {};
    this.keys.map(key => {
      const formControlArray = this.fields[key].required ? ['', Validators.required] : [''];
      formObj[key] = formControlArray;
    });
    this.categoryForm = this.fb.group(formObj);

    if (this.route.snapshot.params.id) {
      this.http.get(`${URL_TO_CALL}/api/Categorie/${this.route.snapshot.params.id}`)
      .subscribe((response: Category) => {
        this.category = response;
        this.categoryForm.patchValue(this.category);
      });
    }
  }

  valueUpdated(event: any) {
    this.categoryForm.patchValue({[event.key]: event.value});
    this.categoryForm.value[event.key] = event.value;
  }

  saveCategory() {
    const values = this.categoryForm.value;
    let url;
    if (this.route.snapshot.params.id) {
      url = `${URL_TO_CALL}/api/Categorie/${this.route.snapshot.params.id}`;
      this.http.put(url, values)
      .subscribe(response => {
        this.openSnackBar('Categoria modificata', 'Ok');
      }, err => {
        this.openSnackBar('Errore del Server', 'Ok');
      });
    } else {
      url = `${URL_TO_CALL}/api/Categorie`;
      this.http.post(url, values)
      .subscribe(response => {
        this.openSnackBar('Categoria Creata', 'Ok');
      }, err => {
        this.openSnackBar('Errore del Server', 'Ok');

      });
    }
  }

}
