import { User } from './../../interfaces/user';
import { UsersService } from './../../services/users.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material';

@Component({
    templateUrl: './users.page.html',
    styleUrls: ['./users.page.scss']
})
// tslint:disable-next-line:component-class-suffix
export class UsersPage implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  users: User[];
  displayedColumns = ['firstName', 'lastName', 'premiumEnd', 'email', 'phone', 'city', 'country', 'vat'];

  constructor(
    private userService: UsersService
  ) {

  }

  ngOnInit(): void {
    this.userService.getAllUsers()
    .subscribe((users: User[]) => {
      this.users = users;
    });
  }
}
