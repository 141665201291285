import { Component, OnInit, Input , EventEmitter, Output} from '@angular/core';
import {Location} from '../../interfaces/location';
@Component({
  selector: 'app-main-location',
  templateUrl: './main-location.component.html',
  styleUrls: ['./main-location.component.scss']
})
export class MainLocationComponent implements OnInit {
  @Input() location: Location;
  @Output() valueUpdated = new EventEmitter<{value: Location, key: string}>();

  lat: number;
  lng: number;

  constructor() { }

  ngOnInit() {
    console.log('CREATED');
    this.lat = this.location ? +(this.location.lat) : 42.555666382032896;
    this.lng = this.location ? +(this.location.lng) : 12.633773803710938;
    this.location = this.location ? this.location : {lat: this.lat, lng: this.lng};
  }

  markerMoved($event) {
    this.lat = $event.coords.lat;
    this.lng = $event.coords.lng;
    this.setMarker();
  }

  setMarker() {
    if ((this.lat) && (this.lng)) {
      console.log('setting marker');
      this.valueUpdated.emit({
        value: {
          lat: this.lat,
          lng: this.lng
        },
        key: 'mainLocation'
      });
    }
  }

}
