import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';
import {Language} from '../../interfaces/language';
import {LANGUAGES} from '../../constants/languages';
@Component({
    selector: 'app-languages',
    templateUrl: './languages.component.html'
})
export class LanguagesComponent implements OnInit {
    @Input() value: Language[];
    @Output() selectedLanguages = new EventEmitter<{key: string, value: Language[]}>();
    allLanguages: Language[] = [];

    constructor() {


    }

    emitSelectedLanguages(language: Language): void {
        language.selected = !language.selected;
        const selecteds: Language[] = this.allLanguages.filter(obj => {
            return obj.selected === true;
        });
        this.selectedLanguages.emit({key: 'languages', value: selecteds});
    }

    ngOnInit() {
        this.allLanguages = LANGUAGES;
        if (this.value) {
            for (const selectedLang of this.value){
                this.allLanguages.filter(obj => {
                    return obj.languageCode === selectedLang.languageCode;
                })[0].selected = true;
            }
        }
    }


}
