import { GcFasceComponent } from './../../components/gc-fasce/gc-fasce.component';
import { TextAreaComponent } from './../../components/text-area/text-area.component';
import { TextFieldComponent } from '../../components/text-field/text-field.component';
import { URL_TO_CALL } from '../../app.module';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { HttpResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Component , OnInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Prodotto } from '../../interfaces/product';
import { FormControl } from '@angular/forms';
import { Product } from '../../interfaces/product';
import { ProductsService } from '../../services/products.service';

import {Field} from '../../interfaces/field';
import { MatSnackBar } from '@angular/material';

@Component({
    templateUrl: './product.page.html',
    styleUrls: ['./product.page.scss']
})
export class ProductPage implements OnInit {

    id: string;
    product: any;
    productForm: FormGroup;
    categories: any;
    productModel = Prodotto;
    keys: any;

    fields: Field[];
    errors: any[];

    constructor(
        private http: HttpClient,
        private route: ActivatedRoute,
        private fb: FormBuilder,
        public snackBar: MatSnackBar
    ) {

    }

    addFascia(event: any) {
        // from output
        if ((!this.product.gcFascePrezzo) || (!this.product.gcFascePrezzo.fasce)) {
            this.product.gcFascePrezzo = {fasce: [event]};
        } else {
            this.product.gcFascePrezzo.fasce.push(event);
        }
    }

    removeTableau(f: any) {
        this.product.gcFascePrezzo.fasce = this.product.gcFascePrezzo.fasce.filter(obj => {
            return obj !== f;
        });
    }

    editTableau(f: any) {
        console.log(f);
    }

    valueUpdated(event: any) {
      console.warn(event);
      this.productForm.controls[event.key].patchValue(event.value);
      console.warn(this.productForm.value);
    }

    ngOnInit() {
      this.id = this.route.snapshot.paramMap.get('id');

      this.http.get('http://51.255.35.185/model/prodotto')
      .subscribe((remoteModel: any) => {
        console.log('REMOTE MODEL', remoteModel);
        const model = remoteModel.info.properties;
        this.fields = model;
        this.keys = Object.keys(model);
        this.http.get(`${URL_TO_CALL}/api/Prodotti/${this.id}`)
        .subscribe(response => {
          console.log('RISPOSTA', response);
          this.product = response;
          this.createForm();
        });
      });

    }

    createForm() {
      const formObj = {};
      this.keys.map(key => {
        if (this.fields[key].show) {
          const formControlArray = this.fields[key].required ? ['', Validators.required] : [''];
          formObj[key] = formControlArray;
        }
        if (this.fields[key].UIType === 'mainLocation') {
          const formControlArray = this.fields[key].required ? ['', Validators.required] : [''];
          formObj[key] = formControlArray;
        }
      });
      this.keys = Object.keys(formObj);
      this.productForm = this.fb.group(formObj);
      this.productForm.patchValue(this.product);
    }

    save() {
      const values = this.productForm.value;
      values.dateUpdated = new Date();
      this.http.patch(`${URL_TO_CALL}/api/Prodotti/${this.product.id}`, values)
      .subscribe(response => {
          this.snackBar.open('Prodotto salvato!', '', {
            duration: 2000,
          });
      }, (err: HttpErrorResponse) => {
        this.snackBar.open('Errore');
      });
    }

}
