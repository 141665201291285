import { Component } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
@Component({
    templateUrl:'./dashboard.page.html',
    styleUrls:['./dashboard.page.scss']
})
export class DashboardPage{
    
    constructor(
        private router: Router,
        private route: ActivatedRoute
    ){

    }

    goTo(where:string){
        this.router.navigate([`${where}`], { relativeTo: this.route })
    }

}