import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Product } from '../interfaces/product';
import { URL_TO_CALL } from '../app.module';

@Injectable()
export class ProductsService {

  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar
  ) { }

  saveProduct(product: Product): any {
    console.log('INVOKED');
    let url = '';
    if (product['id']) {
      url = `${URL_TO_CALL}/api/Prodotti/${product['id']}`;
    } else {
      url = `${URL_TO_CALL}/api/Prodotti/`;
    }
    this.http.post(url, product)
    .subscribe(response => {
      this.snackBar.open('Prodotto creato', '', {
        duration: 2000
      });
      return response;
    });
  }

  getItem(productId: string): Observable<Product> {
    return this.http.get<Product>(`${URL_TO_CALL}/api/Prodotti/${productId}`);
  }
}

