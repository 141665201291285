import { Pipe, PipeTransform } from '@angular/core';

const WEEKDAYS = ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'];

@Pipe({name: 'friendlyDate'})
export class FriendlyDatePipe implements PipeTransform {
  transform(value: any, args: string[]): any {
   return `${WEEKDAYS[value.weekday]} ${value.date}/${value.month + 1}/${value.year}`;
  }
}
