import { OrdersComponent } from './pages/orders/orders.component';
import { UsersService } from './services/users.service';
import { LanguagesComponent } from './components/languages/languages.component';
import { CheckFieldComponent } from './components/check-field/check-field.component';
import { SelectFieldComponent } from './components/select-field/select-field.component';
import { NumberFieldComponent } from './components/number-field/number-field.component';
import { TextAreaComponent } from './components/text-area/text-area.component';
import { ProductPage } from './pages/product/product.page';
import { ProductsPage } from './pages/products/products.page';
import { CategoriesPageComponent } from './pages/categories/categories.page';
import { MainPage } from './pages/main/main.page';
import { UsersPage } from './pages/users/users.page';
import { DashboardPage } from './pages/dashboard/dashboard.page';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatIconModule} from '@angular/material/icon';
import {MatDatepickerModule} from '@angular/material/datepicker';

import { PageNotFoundPage } from './pages/page-not-found/page-not-found.page';
import { LoginPage } from './pages/login/login.page';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {MatSidenavModule} from '@angular/material/sidenav';
import { Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { MatSelectModule, MatNativeDateModule, MatSnackBarModule } from '@angular/material';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { TextFieldComponent } from './components/text-field/text-field.component';
import { GcFasceComponent } from './components/gc-fasce/gc-fasce.component';
import { ImagesComponent } from './components/images/images.component';
import { FriendlyDatePipe } from './pipes/friendly-date.pipe';
import {MatRadioModule} from '@angular/material/radio';
import {MatListModule} from '@angular/material/list';
import {MatDialogModule} from '@angular/material/dialog';


import {ImagesPage} from './pages/images/images.page';
import { NewProductComponent } from './pages/new-product/new-product.component';
import { MainLocationComponent } from './components/main-location/main-location.component';

import { AgmCoreModule } from '@agm/core';
import { ImagesDialogComponent } from './components/images/images-dialog.component';

import { ProductsService } from './services/products.service';
import { ImagesService } from './services/images.service';

import { CategoryDetailComponent } from './pages/category-detail/category-detail.component';
import { RemoteSelectComponent } from './components/remote-select/remote-select.component';
import { OrdersService } from './services/orders.service';

export const URL_TO_CALL = 'http://51.255.35.185';
export const IMAGES_SUFFIX = '/images/';
const appRoutes: Routes = [
  { path: '', component: LoginPage },
  { path: 'dashboard', component: DashboardPage , children: [
    {path: '', component: MainPage},
    {path: 'users', component: UsersPage},
    {path: 'categories', component: CategoriesPageComponent},
    {path: 'categories/edit/:id', component: CategoryDetailComponent},
    {path: 'categories/new', component: CategoryDetailComponent},
    {path: 'products', component: ProductsPage},
    {path: 'products/new', component: NewProductComponent},
    {path: 'products/edit/:id', component: ProductPage},
    {path: 'images', component: ImagesPage},
    {path: 'orders', component: OrdersComponent},
  ]},
  { path: '**', component: PageNotFoundPage }
];
// AIzaSyABIdwQD5L4bSMOwUOkMqo_DzO3yp_aWyg
@NgModule({
  declarations: [
    AppComponent,
    LoginPage,
    PageNotFoundPage,
    DashboardPage,
    UsersPage,
    MainPage,
    ProductsPage,
    ProductPage,
    TextFieldComponent,
    TextAreaComponent,
    NumberFieldComponent,
    SelectFieldComponent,
    CheckFieldComponent,
    GcFasceComponent,
    ImagesComponent,
    ImagesDialogComponent,
    MainLocationComponent,
    FriendlyDatePipe,
    ImagesPage,
    NewProductComponent,
    MainLocationComponent,
    LanguagesComponent,
    CategoriesPageComponent,
    CategoryDetailComponent,
    RemoteSelectComponent,
    OrdersComponent
  ],
  entryComponents: [
    ImagesDialogComponent
  ],
  imports: [
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: true } // <-- debugging purposes only
    ),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyABIdwQD5L4bSMOwUOkMqo_DzO3yp_aWyg'
    }),
    BrowserModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatFormFieldModule,
    MatCardModule,
    FormsModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatSidenavModule,
    HttpClientModule,
    MatTableModule,
    MatSortModule,
    MatSelectModule,
    MatCheckboxModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatListModule,
    MatSnackBarModule
  ],
  providers: [ProductsService, ImagesService, UsersService, OrdersService],
  bootstrap: [AppComponent]
})
export class AppModule { }
