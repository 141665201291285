import { Fascia, FasciaOraria } from './../../interfaces/fascia';
import { FormControl, Validators } from '@angular/forms';
import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ChangeDetectorRef } from '@angular/core';
import * as moment from 'moment';

@Component({
    selector: 'app-gc-fasce',
    templateUrl: './gc-fasce.component.html',
    styleUrls: ['./gc-fasce.component.scss']
})
export class GcFasceComponent implements OnInit {
    @Input() value: Fascia[];
    @Output() added = new EventEmitter();

    excludeFromTo: string;
    from: any;
    to: any;

    QHOURS: string[] = ['00', '15', '30', '45'];
    HOURS: string[] = ['06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16',
    '17', '18', '19', '20', '21', '22', '23', '00', '01', '02', '03', '04', '05'];

    HOURS_TO_SELECT: any[] = [];

    WEEKDAYS: any[] = [{
            name: 'Domenica',
            weekday: 0,
            excluded: false
        }, {
            name: 'Lunedì',
            weekday: 1,
            excluded: false
        },
        {
            name: 'Martedì',
            weekday: 2,
            excluded: false
        },
        {
            name: 'Mercoledì',
            weekday: 3,
            excluded: false
        }, {
            name: 'Giovedì',
            weekday: 4,
            excluded: false
        }, {
            name: 'Venerdì',
            weekday: 5,
            excluded: false
        }, {
            name: 'Sabato',
            weekday: 6,
            excluded: false
        }
    ];


    formFascia: FormGroup;
    daysOfTheYear: any[] = [];

    constructor(private fb: FormBuilder,
        private sfb: FormBuilder) {
        this.createForm();
        for (const hour of this.HOURS){
            for (const qhour of this.QHOURS){
                this.HOURS_TO_SELECT.push({
                    selected: false,
                    text: `${hour}:${qhour}`
                });
            }
        }
    }

    ngOnInit() {

        for (let i = 0; i < 365; i++) {
            const now = new Date();
            const day = this.addDays(now, i);
            const dayObj = {
                weekday: day.getDay(),
                month: day.getMonth(),
                year: day.getFullYear(),
                date: day.getDate(),
                originalDate: day,
                excluded: false
            };
            this.daysOfTheYear.push(dayObj);
        }

        console.log(this.daysOfTheYear);
    }

    addHour(hour) {
        hour.selected = !hour.selected;
    }

    applyFromTo() {
        const values = this.formFascia.value;
        console.log(values);
        const from = values.from;
        const to = values.to;
        let boolValue;
        if (values.excludeFromTo === 'e') {
            boolValue = true;
        } else {
            boolValue = false;
        }
        for (let i = 0; i < 365; i++) {
            const day = this.daysOfTheYear[i];
            if ((day.originalDate > from) && (day.originalDate < to)) {
                console.log('è da includere', boolValue, values.excludeFromTo);
                day.excluded = boolValue;
            }
        }
    }

    applyFromToDays() {
        const values = this.formFascia.value;
        console.log(values);
        const from = values.from;
        const to = values.to;
        let boolValue;
        if (values.excludeFromToWeekDay === 'e') {
            boolValue = true;
        } else {
            boolValue = false;
        }

        const allowedDays = this.WEEKDAYS
        .filter((obj) => obj.excluded === true)
          .map((obj) =>  obj.weekday);
        console.log(allowedDays);
        for (let i = 0; i < 365; i++) {
            const day = this.daysOfTheYear[i];
            if (allowedDays.includes(day.weekday)) {
                day.excluded = boolValue;
            }
        }
    }

    addToSelected(day) {
        day.excluded = !day.excluded;
    }

    createTableau() {
        const values = this.formFascia.value;
        console.log('creato');
        const okDays = this.daysOfTheYear.filter(obj => {
            return obj.excluded === true;
        });
        console.log(okDays);
        const okHours = this.HOURS_TO_SELECT.filter((obj) => {
            return obj.selected === true;
        });
        const finalObj = {
            days: okDays,
            from: values.dalle,
            to: values.alle,
            hours: okHours
        };

        this.added.emit(finalObj);
        this.formFascia.controls.nome.setValue('');
    }

    createPoolOfIncludedDays(day) {
        day.excluded = !day.excluded;
    }

    addToexcluded(day) {
        day.excluded = !day.excluded;
    }

    addDays(date, minutes) {
        return new Date(date.getTime() + minutes * 60000 * 60 * 24);
    }

    createForm() {
        const now = new Date();
        this.formFascia = this.fb.group({
            nome: ['', [Validators.required, Validators.minLength(6)]],
            dalle: '',
            alle: '',
            excludeFromTo: 'i',
            from: now,
            to: this.addDays(now, (7)),
            excludeFromToWeekDay: 'i'
        });
    }

}

/*
interface FasciaOraria{
    orarioFascia:string,
    prezzoFascia:number
}

export interface Fascia{
    nomeFascia:string,
    fasceOrarie:FasciaOraria[],
    dateChiusura : string[]
}
 */
