import { Language } from './language';
import { Location } from './location';
import { FasciaOraria } from './fascia';
import { Fascia } from './fascia';

export const Prodotto = {
    provider:     {type: 'string', optional: false},
    name:         {type: 'string', optional: false},
    currency:     {type: 'string', optional: false},
    mainLocation: {type: 'mainLocation', optional: false},

    productCode:  {type: 'string', optional: true},
    productType:  {type: 'string', optional: true},
    category:     {type: 'select', optional: true, provider: 'http://51.255.35.185:3000/api/Categorie'},
    shortDescription:           {type: 'string', optional: true},
    description:  {type: 'text', optional: true},
    terms:        {type: 'text', optional: true},
    images:       {type: 'images', optional: true},
    timezone:     {type: 'string', optional: true},
    quantityRequired:           {type: 'string', optional: true},
    quantityRequiredMin:        {type: 'number', optional: true},
    quantityRequiredMax:        {type: 'number', optional: true},
    bookingMode:  {type: 'string', optional: true},
    durationMinutes:            {type: 'string', optional: true},
    languages:    {type: 'languages', optional: true},
    gcPrezzoAcquisto:           {type: 'number', optional: true},
    gcPrezzoAlPubblico:         {type: 'number', optional: true},
    gcBasicPrice: {type: 'number', optional: true},
    gcPremiumPrice:             {type: 'number', optional: true},
    gcCreditPrice: {type: 'number', optional: true},
    gcFascePrezzo: {type: 'gcFasce', optional: true},
    categoriaId:  {type: 'string', optional: true},
    prodottoId:   {type: 'string', optional: true}
};

export interface Product {

    provider: string;
    name: string;
    currency: 'EUR'|'USD'|'GBP';
    id?: string;
    productCode?: string;
    productType?: string;
    category?: string;
    shortDescription?: string;
    description?: string;
    terms?: string;
    images?: any;
    timezone?: string;
    advertisedPrice?: string;
    mainLocation?: Location;
    quantityRequired?: string;
    quantityRequiredMin?: number;
    quantityRequiredMax?: number;
    bookingMode?: string;
    durationMinutes?: string;
    languages?: Language[];
    gcPrezzoAcquisto?: number;
    gcPrezzoAlPubblico?: number;
    gcBasicPrice?: number;
    gcPremiumPrice?: number;
    gcCreditPrice?: number;
    gcFascePrezzo?: Fascia[];
    categoriaId?: string;
    prodottoId?: string;
}
