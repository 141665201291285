import { MatSnackBar } from '@angular/material';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Product } from '../interfaces/product';
import { URL_TO_CALL } from '../app.module';
import { User } from '../interfaces/user';
import { Observable } from 'rxjs';

@Injectable()
export class UsersService {

  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar
  ) { }

  getAllUsers(): Observable<User[]> {
      return this.http.get<User[]>(`${URL_TO_CALL}/api/Clienti`);
  }

  getUser(userId: string): Observable<User> {
    return this.http.get<User>(`${URL_TO_CALL}/api/Clienti/${userId}`);
  }
}

