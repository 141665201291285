import { FormControl } from '@angular/forms';
import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
@Component({
    selector:'text-area',
    templateUrl:'./text-area.component.html',
    styleUrls:['../../shared/shared.scss']
})
export class TextAreaComponent{
    @Input() config:any;
    @Input() key:string;
    @Input() value:string;
    @Input() c:FormControl;
    @Output() valueUpdated = new EventEmitter<any>();

    valueChanged(value:any){
        this.valueUpdated.emit({
            value:value,
            key:this.key
        })
    }

    ngOnInit(){

    }
}