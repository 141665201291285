import {Component, OnInit} from '@angular/core';
import { ImagesService } from '../../services/images.service';
import {IMAGES_SUFFIX, URL_TO_CALL} from '../../app.module';

@Component({
    templateUrl: './images.page.html',
    styleUrls: ['../../shared/shared.scss']
})
export class ImagesPage implements OnInit {
    files: any;
    uploadStatus: any;
    imagesContainer = [];
    images: any;
    suffix = IMAGES_SUFFIX;
    baseUrl = URL_TO_CALL;
    loading = false;

    constructor(
      private imgService: ImagesService
    ) {

    }

    ngOnInit() {
      this.factorImages();
    }

    factorImages() {
      this.imagesContainer = [];
      this.images = [];
      this.imgService.loadAllImages()
      .subscribe(images => {
        this.images = images;
        for (const img of this.images){
          img.visible = true;
        }
        this.chunkArray(this.images);
      });
    }

    filterByWord(word: string) {
      this.images = this.imgService.filterByWord(word, this.images);
    }

    delete(img: any): void {
      const response = confirm('Cancellare definitivamente l\'immgine?');
      if (response) {
        this.imgService.delete(img)
        .subscribe(response => {
          setTimeout( () => {
            this.factorImages();
          }, 500);
        });
      }
    }

    chunkArray(array: any[]) {
      const chunk = 6;
      let tempArray = [];
      for (let i = 0, j = array.length; i < j; i += chunk) {
        tempArray = array.slice(i , i + chunk);
        this.imagesContainer.push(tempArray);
      }

      console.log(this.imagesContainer);
    }

    uploadFiles(url, file) {

        const xhr =  new XMLHttpRequest();
        const formData = new FormData();

        formData.append('file', file);

        xhr.onreadystatechange = () => {
            console.log('LA RISPOSTA', xhr);
            if (xhr.status === 4) {
              setTimeout(() => {
                alert('refactoring');
                this.factorImages();
              }, 500);
                this.uploadStatus = {
                color: 'green',
                message: 'upload riuscito'
              };
            } else {
              this.loading = false;
                this.uploadStatus = {
                  color: 'goldenrod',
                  message: 'verifico'
                };
            }
        };

        xhr.open('POST', url, true);
        xhr.onload = (e: any) => {
            console.info(e);
            if (e.loaded === e.total) {
              this.loading = false;
            }
        };
        xhr.send(formData); // multipart/form-data
    }

    uploadPics(files: any) {
      this.loading = true;
        console.log('about to upload', files);
        this.uploadFiles('http://51.255.35.185/api/attachments/images/upload', files);
    }

}

