import {Language} from '../interfaces/language';

export const LANGUAGES:Language[] = [{
    languageCode:'it',
    commonName:'Italiano',
    selected: false
},
{
    languageCode:'fr',
    commonName:'Francese',
    selected: false
},{
    languageCode:'en',
    commonName:'Inglese',
    selected: false
},{
    languageCode:'es',
    commonName:'Spagnolo',
    selected: false
},{
    languageCode:'pt',
    commonName:'Portoghese',
    selected: false
},{
    languageCode:'ru',
    commonName:'Russo',
    selected: false
},{
    languageCode:'cn',
    commonName:'Cinese',
    selected: false
},{
    languageCode:'de',
    commonName:'Tedesco',
    selected: false
},{
    languageCode:'nl',
    commonName:'Olandese',
    selected: false
},{
    languageCode:'dk',
    commonName:'Danese',
    selected: false
},{
    languageCode:'fi',
    commonName:'Finlandese',
    selected: false
},{
    languageCode:'se',
    commonName:'Svedese',
    selected: false
},{
    languageCode:'no',
    commonName:'Norvegese',
    selected: false
},]

